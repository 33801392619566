/* eslint-disable @typescript-eslint/no-this-alias */
import React from "react";
import PropTypes from "prop-types";

import TextEditor from "./TextEditor";
import { _post, _get, _put } from "./../_util/apiBase";
import { v4 as uuid } from "uuid";
import {
  addWrapper,
  getMathMl,
  trimAllDsAuthTokens,
  removeWrapper,
} from "./Utils";

const REFRESH_BUTTON_MODE = {
  OV_ADD_PAGE: "OV_ADD_PAGE"
}

class SmartTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dsAuthToken: props.dsAuthToken,
      signedUrl: null,
      htmlContent: "",
      documentId: props.documentId,
      initialHtmlContent: "",
      imageUrls: [],
      fileGroupId: null,
      akamaiCdnToken: props.akamaiCdnToken,
      updatedAt: "",
      editor: null,
      key: uuid(),
    };
  }

  editorOnChange = (data) => {
    this.setState({
      htmlContent: data,
    }, () => {
      this.props.onChange && this.props.onChange(data);
    });    
  };

  triggerSave = (callback) => {
    console.log("save triggered");
    if (this.state.documentId) {
      this.updateHtml(callback);
    } else {
      this.saveHtml(callback);
    }
  };

  getContent = () => {
    return this.state.htmlContent;
  };

  componentDidMount() {
    const smartEditorInstance = {
      triggerSave: this.triggerSave,
      getContent: this.getContent,
    };

    this.props.onReady && this.props.onReady(smartEditorInstance);
    
    //this.getAuthToken();
    if (this.props.documentId) {
      this.getSignedUrl(this.props.documentId);
    } else {
      this.setState({ fileGroupId: uuid() });
    }
  }

  image_upload_handler = (blobInfo, success, failure, progress) => {
    this.uploadImage(blobInfo, success, failure, progress);
  };

  uploadImage = (blobInfo, success, failure, progress) => {
    const url = `${this.props.documentServiceUrl}/api/v2/documents/html/image?prefix=${this.props.prefix}&fileGroupId=${this.state.fileGroupId}`;
    const file = blobInfo.blob();

    let formData = new FormData();
    formData.append("upload", file);

    const headers = {
      "Content-Type": "multipart/form-data",
      "correlation-id": this.props.correlationId,
      "x-tenantId": this.props.tenantId,
      "X-DsAuthToken": this.state.dsAuthToken,
      "X-Authorization": this.props.xAuthToken,
    };

    _post(
      url,
      headers,
      formData,
      (response) => {
        const url = `${response.data.path}&${this.props.akamaiCdnToken}`;
        success(url);
      },
      (response) => {
        failure("An error occurred! Image upload failed.");
        this.props.onError && this.props.onError({ event: "IMAGE_UPLOAD_ERROR", data: response });
      }
    );
  };

  getAuthToken = () => {
    const url = `${this.props.documentServiceUrl}/api/v2/generateauthtoken`;
    const headers = {
      "correlation-id": this.props.correlationId,
      "x-tenantId": this.props.tenantId,
      "X-Authorization": this.props.xAuthToken,
      "Content-Type": "Application/json",
    };
    const data = {
      prefix: this.props.prefix,
      permissionLevel: "READ",
    };
    _post(
      url,
      headers,
      data,
      (response) => {
        console.log(response);
        this.setState(
          {
            dsAuthToken: response.data.dsAuthToken,
            akamaiCdnToken: response.data.akamaiCdnToken,
          },
          () => {
            if (this.state.documentId) {
              this.getSignedUrl(this.state.documentId);
            } else {
              this.setState({ fileGroupId: uuid() });
            }
          }
        );
      },
      (response) => {
        console.log(response);
      }
    );
  };

  getSignedUrl = (documentId) => {
    const url = `${this.props.documentServiceUrl}/api/v2/documents/${documentId}`;
    const headers = {
      "correlation-id": this.props.correlationId,
      "x-tenantId": this.props.tenantId,
      "X-Authorization": this.props.xAuthToken,
      "X-DsAuthToken": this.props.dsAuthToken,
      "Content-Type": "Application/json",
    };
    _get(
      url,
      headers,
      (response) => {
        this.setState(
          {
            signedUrl: `${response.data.documentUrl}&${this.props.akamaiCdnToken}`,
            fileGroupId: response.data.fileGroupId,
            updatedAt: response.data.updatedAt,
          },
          () => {
            this.getHtml(
              `${response.data.documentUrl}&${this.props.akamaiCdnToken}`
            );
          }
        );
      },
      (response) => {
        this.props.onError && this.props.onError({ event: "GET_SIGNED_URL_ERROR", data: response });
      }
    );
  };

  prepareContent = (content) => {
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(content, "text/html");
    //append script tags in content to body as content is not visible
    // when scripts are loaded in head
    const tmpScripts = parsedHtml.querySelectorAll("script");
    if (tmpScripts.length > 0) {
      for (let i = 0; i < tmpScripts.length; i++) {
        parsedHtml.body.appendChild(tmpScripts[i]);
      }
    }
    return addWrapper(getMathMl(trimAllDsAuthTokens(parsedHtml)));
  };

  saveHtml = (callback) => {
    const url = `${this.props.documentServiceUrl}/api/v2/documents/html`;

    const headers = {
      "Content-Type": "application/json",
      "correlation-id": this.props.correlationId,
      "x-tenantId": this.props.tenantId,
      "X-DsAuthToken": this.props.dsAuthToken,
      "X-Authorization": this.props.xAuthToken,
    };

    const body = {
      content: this.prepareContent(this.state.htmlContent),
      fileGroupId: this.state.fileGroupId,
      prefix: this.props.prefix,
      fileName: "index.html",
    };

    _post(
      url,
      headers,
      body,
      (response) => {
        this.setState({
          documentId: response.data.documentId,
          updatedAt: response.data.updatedAt,
        });
        if (callback) {
          callback({ status: "success", data: response.data });
        }
      },
      (response) => {
        if (callback) {
          callback({ status: "error", data: response });
        }
      }
    );
  };

  updateHtml = (callback) => {
    const url = `${this.props.documentServiceUrl}/api/v2/documents/${this.state.documentId}/html`;

    const headers = {
      "Content-Type": "application/json",
      "correlation-id": this.props.correlationId,
      "x-tenantId": this.props.tenantId,
      "X-DsAuthToken": this.props.dsAuthToken,
      "X-Authorization": this.props.xAuthToken,
    };

    const body = {
      content: this.prepareContent(this.state.htmlContent),
      updatedAt: this.state.updatedAt,
    };

    _put(
      url,
      headers,
      body,
      (response) => {
        this.setState({
          documentId: response.data.documentId,
          updatedAt: response.data.updatedAt,
        });
        if (callback) {
          callback({ status: "success", data: response.data });
        }
      },
      (response) => {
        if (response.response.status === 409) {
          this.state.editor.notificationManager.open({
            text: "This content is modified by another user. Please copy the changes and reload",
            type: "error",
          });
        }
        if (callback) {
          callback({ status: "error", data: response });
        }
      }
    );
  };

  getHtml = (signedUrl) => {
    const url = signedUrl;
    const headers = {
      "Content-Type": "text/html",
    };
    _get(
      url,
      headers,
      (response) => {
        this.setState(
          {
           
            initialHtmlContent: removeWrapper(response.data),
            key: uuid(),
          },
          () => {/**/
          }
        );
      },
      (response) => {
        this.props.onError && this.props.onError({ event: "GET_HTML_ERROR", data: response });
      }
    );
  };

  setEditorInstance = (editorInstance) => {
    this.setState({
      editor: editorInstance,
    });
  };

  refreshEditor = (editor) => {
    if (this.state.documentId) {
      this.getSignedUrl(this.state.documentId);
    }else{
      if(this.props.refreshButtonMode && this.props.refreshButtonMode === REFRESH_BUTTON_MODE.OV_ADD_PAGE){
        editor.setContent("");
      }
    }
  };

  render() {
    const baseUrl = `${this.props.contentServiceUrl}/${this.props.prefix}/${this.state.fileGroupId}/`;
    return (
      <>
        {this.state.fileGroupId && (
          <TextEditor
            key={this.state.key}
            mathEditor={this.props.mathEditor}
            initialContent={this.state.initialHtmlContent}
            tinymceApiKey={this.props.tinymceApiKey}
            onChange={this.editorOnChange}
            imageUploadHandler={this.image_upload_handler}
            height={this.props.height}
            baseUrl={baseUrl}
            setEditorInstance={this.setEditorInstance}
            refreshEditor={this.refreshEditor}
            toolbar={this.props.toolbar}
            menubar={this.props.menubar}
            insertItems={this.props.insertItems}
            loader={this.props.loader}
            contentStyle={this.props.contentStyle}
            placeholderText={this.props.placeholderText}
          ></TextEditor>
        )}
      </>
    );
  }
}

SmartTextEditor.propTypes = {
  /** turn matheditor on and off*/
  mathEditor: PropTypes.bool,
  /** tinymce api key */
  tinymceApiKey: PropTypes.string,
  /** on change will return the changed html string */
  onChange: PropTypes.func,
  /** on ready will return the text editor instance to the consumer where it contains the save, get content functions */
  onReady: PropTypes.func.isRequired,
  /** on error will return error event and API response data */
  onError: PropTypes.func.isRequired,
  /** access token to upload images */
  dsAuthToken: PropTypes.string.isRequired,
  /** PI token */
  xAuthToken: PropTypes.string.isRequired,
  /** toolbar order, in tinymce accepted format */
  toolbar: PropTypes.string,
  /** Customise the menubar, in tinymce accepted format */
  menubar: PropTypes.string,
  /** Customise options of Insert in menubar, in tinymce accepted format */
  insertItems: PropTypes.string,
  documentId: PropTypes.string,
  tenantId: PropTypes.string,
  correlationId: PropTypes.string,
  prefix: PropTypes.string,
  /** text editor height */
  height: PropTypes.number,
  /** provide document service url based on env */
  documentServiceUrl: PropTypes.string,
  /** provide the content service url based on env */
  contentServiceUrl: PropTypes.string,
  /** provide authroken for content cdn urls */
  akamaiCdnToken: PropTypes.string,
  loader: PropTypes.bool,
  contentStyle: PropTypes.string,
  placeholderText: PropTypes.string,
};

SmartTextEditor.defaultProps = {
  mathEditor: false,
  tinymceApiKey: "8rx0rjuz205wkmwv32ddw2a1hovrsavxlnx12rrkk4e594wb",
  height: 600,
  toolbar: "Focus Refresh | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview  print | insertfile image media link anchor codesample Math code | ltr rtl",
  menubar: "file edit view insert format tools table help",
  insertItems: "image link media inserttable Math codesample | charmap emoticons hr | pagebreak anchor toc | insertdatetime",
  loader: false,
  contentStyle: "",
  placeholderText: null
};

export default SmartTextEditor;
