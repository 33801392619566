/* eslint-disable @typescript-eslint/no-this-alias */
import styles from "./matheditor.module.scss";
import React from "react";

import MathEditor from "./MathEditor";
import LoadingIndicator from "./../LoadingIndicator";
import { Editor } from "@tinymce/tinymce-react";
import { loadScript } from "../_util/common";
import PropTypes from "prop-types";
const $ = window.jQuery;
window.$ = window.jQuery;

class TextEditor extends React.Component {
  constructor() {
    super();
    this.state = {
      editorIsOpen: false,
      content: undefined,
      contentId: undefined,
      editorLoader: true,
      isClearPlaceholder: true,
    };
    this.editor = undefined;
    this.handleEditorLoad = this.handleEditorLoad.bind(this);
  }

  setEditorOpen = (value, contentId = undefined, content = "") => {
    let math = "";
    if (content) {
      math = JSON.parse(content.getAttribute("mathdata"));
    }

    this.setState({
      editorIsOpen: value,
      content: math,
      contentId,
    });
  };

  handleEditorChange = (content) => {
    this.props.onChange(content);
  };

  setEditorInstance = () => {
    if (typeof this.props.setEditorInstance === "function") {
      this.props.setEditorInstance(this.editor);
    }
  };

  getExpressionsByID(html) {
    let expressions = {};
    const jhtml = $(`<div>${html}</div>`);
    jhtml.find(`.pmml`).each((idx, elem) => {
      const pmmlClsName = window
        .$(elem)
        .attr("class")
        .split(/\s+/)
        .find((clstr) => clstr.match(/pmml-key/));
      expressions[pmmlClsName] = elem.innerHTML;
    });
    return expressions;
  }

  replaceExpressions(expressions, html) {
    const jhtml = $(`<div>${html}</div>`);
    jhtml.find(`.pmml`).each((idx, elem) => {
      const pmmlClsName = window
        .$(elem)
        .attr("class")
        .split(/\s+/)
        .find((clstr) => clstr.match(/pmml-key/));
      if (expressions[pmmlClsName]) {
        window.$(elem).html(expressions[pmmlClsName]);
      }
    });
    return jhtml.html();
  }

  disableContentEditable = (str) => {
    return str
      .replace(
        new RegExp(
          '<div class="pmml mceNonEditable ppbMathElement pmml-key-',
          "g"
        ),
        '<div contenteditable="false" class="pmml mceNonEditable ppbMathElement pmml-key-'
      )
      .replace(
        new RegExp('class="mce-item pmml-child mceNonEditable pmml-key-', "g"),
        'contenteditable="false" class="mce-item pmml-child mceNonEditable pmml-key-'
      );
  };

  setMathContent = (pmml, cmml, svg, mlId, oldMlId) => {
    // cmml = cmml.replace(/<\/body>/gi,"").replace(/<body>/gi,"")
    const finalPMML = `<div class="pmml mceNonEditable ppbMathElement pmml-key-${mlId}">
            <div onclick="window.parent.$.data(parent.document.body, 'modify','${mlId}');
            window.parent.toggleMathEditor(true, '${mlId}', this);"
            ontouchstart="window.parent.$.data(parent.document.body, 'modify','${mlId}'); window.parent.touchToggleMathEditor(event,true, '${mlId}', this);"
            class="mce-item pmml-child mceNonEditable pmml-key-${mlId}" mathdata='${JSON.stringify(
      cmml
    )}'  pmmldata='${JSON.stringify(svg)}'
    >${svg}</div>
            </div> `;

    const fhtml = finalPMML;
    if (oldMlId) {
      window
        .$(this.editor.iframeElement)
        .contents()
        .find("body")
        .find(`.pmml.pmml-key-${oldMlId}`)
        .replaceWith(this.disableContentEditable(fhtml));
      this.editor.setDirty(true);
      this.editor.fire("change");
      if (this.editor.getDoc().defaultView.MathJax.typeset) {
        this.editor.getDoc().defaultView.MathJax.typeset();
      }
      this.editor.execCommand("mceCleanup", false, fhtml);
    } else {
      this.editor.execCommand("mceInsertContent", false, fhtml);
    }
  };

  componentDidMount() {
    window.toggleMathEditor = this.setEditorOpen;
    window.touchToggleMathEditor = (e, value, contentId, content) => {
      e.preventDefault();
      this.setEditorOpen(value, contentId, content);
    };

    if (this.props.loader) {
      this.setState({
        editorLoader: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialContent !== prevProps.initialContent) {
      this.editor.setContent(this.props.initialContent);
    }
  }

  getMathMl = (content) => {
    let parser = new DOMParser();
    var contentElement = document.createElement("div");
    //remove no script
    content = content.replace(/<noscript[\s\S]*?>[\s\S]*?<\/noscript>/gi, "");
    contentElement.innerHTML = content;
    var header = document.createElement("head");
    if (contentElement.getElementsByTagName("head").length === 0) {
      contentElement.prepend(header);
    }
    contentElement.querySelectorAll("link").forEach((e) => {
      contentElement.getElementsByTagName("head")[0].appendChild(e);
    });
    let parsedHtml = parser.parseFromString(
      contentElement.innerHTML,
      "text/html"
    );
    const mathElements = parsedHtml.querySelectorAll(".pmml-child");
    mathElements.forEach(function (element) {
      const originalMathContent = JSON.parse(element.getAttribute("pmmldata"));
      const mathElement = parsedHtml.querySelector(
        `.pmml-child.${element.classList[3]}`
      );
      mathElement.innerHTML = originalMathContent;
    });
    return `${parsedHtml.head.outerHTML}${parsedHtml.body.outerHTML}`;
  };

  onBeforeSetContent = (content) => {
    content.content = this.getMathMl(content.content);
  };

  customMediaResolver = (data, resolve /*, reject*/) => {
    let embedHtml = "";
    let iFrame_1 = '<iframe src="https://www.youtube.com/embed/';
    let iFrame_2 = '" width="400" height="200" ></iframe>';
    if (data.url.indexOf(data.url) !== -1) {
      if (data.url.includes("https://youtu.be")) {
        embedHtml = iFrame_1 + data.url.split("youtu.be/", 2).pop() + iFrame_2;
        resolve({ html: embedHtml });
      } else if (data.url.includes("https://www.youtube")) {
        embedHtml =
          iFrame_1 +
          data.url.split("youtube.com/watch?v=", 2).pop().replace("&", "?") +
          iFrame_2;
        resolve({ html: embedHtml });
      } else {
        embedHtml = '<iframe src="' + data.url + iFrame_2;
        resolve({ html: embedHtml });
      }
    } else {
      resolve({ html: "" });
    }
  };

  handleEditorLoad() {
    loadScript(
      "Mathjax lib",
      "https://learningapps-ui.pearson.com/b0/lass-ui-cdn-libs/v1.2.0/matheditor/js/mathjax.js",
      () => {
        this.setState({
          editorLoader: true,
        });
      }
    );
    console.log("Mathjax Lib loaded");
  }

  handleEditorInit(editor) {
    // this fix is specific for OV - LAF-6982
    const specificSelector = ".ov-footer";
    const specificElement = document.querySelector(specificSelector);
    if (specificElement) {
      const reactModalContent = document.querySelector(".ReactModal__Content");
      const originalTransform = reactModalContent.style.transform;

      editor.on("FullscreenStateChanged", (event) => {
        if (event.state) {
          reactModalContent.style.transform = "none";
        } else {
          reactModalContent.style.transform = originalTransform;
        }
      });
    }
  }

  render() {
    const { editorIsOpen, content, contentId, editorLoader } = this.state;
    const { contentStyle } = this.props;
    const that = this;
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingIndicator
            strokeWidth="5"
            color="#047a9c"
            width="50px"
            height="50px"
            dashDuration="2s"
            rotateDuration="2s"
            isHidden={editorLoader}
            isLoading
          />
        </div>
        <div
          className={styles.matheditor_container}
          style={!editorLoader ? { display: "none" } : {}}
        >
          <div id="mathjax_rendering_sandbox" style={{ display: "none" }}></div>
          {this.props.mathEditor && (
            <MathEditor
              isOpen={editorIsOpen}
              content={content || ""}
              contentId={contentId}
              closeEditor={() => this.setEditorOpen(false)}
              setContent={(pmml, cmml, svg, mlId, oldMlId, mml) =>
                this.setMathContent(pmml, cmml, svg, mlId, oldMlId, mml)
              }
            />
          )}
          <Editor
            // apiKey={this.props.tinymceApiKey}
            tinymceScriptSrc="https://learningapps-ui.pearson.com/b0/lass-ui-cdn-libs/v1.2.0/tinymce/v5.10.7/js/tinymce/tinymce.min.js"
            initialValue={this.props.initialContent}
            onInit={this.handleEditorLoad}
            init={{
              automatic_uploads: false,
              convert_urls: false,
              ie7_compat: false,
              force_br_newlines: false,
              force_p_newlines: false,
              default_link_target: "_blank",
              link_context_toolbar: true,
              link_assume_external_targets: true,
              link_default_protocol: "https",
              link_title: false,
              a11y_advanced_options: true,
              init_instance_callback: (editor) => this.handleEditorInit(editor),
              //   fullpage_hide_in_source_view: false,
              custom_elements:
                "math,maction,maligngroup,malignmark,menclose,merror,mfenced,mfrac," +
                "mglyph,mi,mlabeledtr,mlongdiv,mmultiscripts,mn,mo,mover,mpadded,mphantom,mroot," +
                "mrow,ms,mscarries,mscarry,msgroup,msline,mspace,msqrt,msrow,mstack,mstyle,msub," +
                "msup,msubsup,mtable,mtd,mtext,mtr,munder,munderover,svg,mjx-container,defs,path,g,use,mjx-assistive-mml",
              extended_valid_elements:
                "span[*],line[x1|y1|x2|y2],g[stroke-width|stroke|fill|transform],svg[style|role]" +
                ",div[id|onclick|style|ontouchstart|class|contenteditable|xmlns|mathdata|pmmldata],path[id|d],input[accept|alt|checked|disabled|maxlength|name|readonly|size|src|type|value|onclick|type]" +
                ",audio[id],source[src|type],img[longdesc|usemap|src|border|alt=|title|hspace|vspace|width|height|align|onclick|class|data-mce-resize|data-mce-placeholder|style]" +
                ",mo[stretchy],mspace[width|linebreak],mtd[columnalign],mpadded[width],mfenced[open|close],menclose[notation],em[*],script[*],a[*],li[*],ul[*],link[*],head[*],title[*],i[*]",
              content_style:
                "math, mjx-container { display:block; line-height: 1.4 !important }" +
                contentStyle,
              content_css:
                "https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap, https://fonts.googleapis.com/css2?family=UnifrakturMaguntia&display=swap, https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css,  https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap",
              plugins:
                "a11ychecker print preview paste searchreplace autolink autosave save directionality code visualblocks " +
                "visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking " +
                "anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
              menubar: this.props.menubar,
              menu: {
                file: { title: "File", items: "preview | print " },
                edit: {
                  title: "Edit",
                  items:
                    "undo redo | cut copy paste pastetext | selectall | searchreplace",
                },
                view: {
                  title: "View",
                  items: "code | spellchecker | preview fullscreen",
                },
                insert: {
                  title: "Insert",
                  items: this.props.insertItems,
                },
                format: {
                  title: "Format",
                  items:
                    "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat",
                },
                tools: {
                  title: "Tools",
                  items: "spellchecker | code wordcount",
                },
                table: {
                  title: "Table",
                  items:
                    "inserttable | cell row column | tableprops deletetable",
                },
              },

              media_poster: false,
              media_alt_source: false,
              media_url_resolver: this.customMediaResolver,
              toolbar: this.props.toolbar,
              toolbar_sticky: false,
              height: this.props.height,
              quickbars_selection_toolbar:
                "bold italic | quicklink h2 h3 blockquote quicktable",
              quickbars_insert_toolbar: false,
              noneditable_noneditable_class: "mceNonEditable",
              toolbar_mode: "sliding",
              contextmenu: "link image imagetools table",

              image_title: false,
              image_description: false,
              image_dimensions: false,
              images_upload_handler: this.props.imageUploadHandler,
              images_file_types: "jpg,png,jpeg",
              document_base_url: this.props.baseUrl,
              mobile: {
                toolbar_mode: "sliding",
                menubar: this.props.menubar,
              },
              fullscreen_native: true,
              setup: function (editor) {
                that.editor = editor;
                that.setEditorInstance();

                //Editor configuration for placeholder
                const editorConfig = {
                  suppressUndo: true,
                  suppressRedo: true,
                };

                const getStringFromHtmlContent = (htmlContent) => {
                  let parser = new DOMParser();
                  let parsedDoc = parser.parseFromString(
                    htmlContent,
                    "text/html"
                  );
                  return parsedDoc.body.textContent;
                };

                //Add a focus event listener to the editor content area and blur event for when the focus is moved to any of the editors UI components
                editor
                  .on("focus", function () {
                    if (
                      getStringFromHtmlContent(that.props.placeholderText) !==
                        null &&
                      that.state.isClearPlaceholder &&
                      getStringFromHtmlContent(that.props.placeholderText) ===
                        getStringFromHtmlContent(editor.getContent())
                    ) {
                      editor.setContent("", editorConfig);
                      that.setState({ isClearPlaceholder: false });
                    }
                  })
                  .on("blur", function () {
                    if (
                      that.props.placeholderText !== null &&
                      !that.state.isClearPlaceholder &&
                      getStringFromHtmlContent(editor.getContent()) === ""
                    ) {
                      editor.setContent(
                        that.props.placeholderText,
                        editorConfig
                      );
                      that.setState({ isClearPlaceholder: true });
                    }
                  });

                // A polite alert instructs assistive to wait and announce the information
                // when there is a natural pause, such as when the user stops typing. (Word count)
                editor.on("init", function (event) {
                  // Select the button element by its class
                  let wordButtonElement = document.querySelector(
                    ".tox-statusbar__wordcount"
                  );

                  // Check if the button element exists
                  if (wordButtonElement) {
                    // Add the aria-live attribute
                    wordButtonElement.setAttribute("aria-live", "polite");
                  }
                });

                //Block unsecured protocol http on link plugin
                editor.on("BeforeSetContent", function (event) {
                  const content = event.content;
                  const httpLinks = content.match(/<a href="http:\/\/[^"]*"/g);
                  const httpBlobImageLinks = content.match(
                    /<img src="blob:http:\/\/[^"]*"/g
                  );
                  const httpImageLinks = content.match(
                    /<img src="http:\/\/[^"]*"/g
                  );
                  const httpVideoLinks = content.match(
                    /<iframe src="http:\/\/[^"]*"/g
                  );

                  if (
                    httpLinks ||
                    httpBlobImageLinks ||
                    httpImageLinks ||
                    httpVideoLinks
                  ) {
                    editor.windowManager.alert(
                      "HTTP links are not allowed. Please use HTTPS links instead."
                    );
                    event.preventDefault();
                  }
                });

                editor.ui.registry.addButton("Math", {
                  text: "Math",
                  tooltip: "Math equation",
                  onAction: function (_) {
                    that.setEditorOpen(true);
                  },
                });

                editor.ui.registry.addButton("Focus", {
                  text: "Focus",
                  tooltip: "Focus editor",
                  onAction: function (_) {
                    that.editor.focus();
                  },
                });

                editor.ui.registry.addButton("Refresh", {
                  icon: "reload",
                  tooltip: "Refresh editor",
                  onAction: function (_) {
                    if (typeof that.props.refreshEditor === "function") {
                      that.props.refreshEditor(editor);
                    }
                  },
                });

                editor.ui.registry.addMenuItem("Math", {
                  text: "Math equation",
                  tooltip: "Math equation",
                  onAction: function () {
                    that.setEditorOpen(true);
                  },
                });

                editor.on("OpenWindow", function (eventDetails) {
                  const dialogTitle = $(".tox-dialog__title").text();

                  if (dialogTitle === "Insert/Edit Image") {
                    $(":button.tox-button[title='Save']").html("Insert"); //Chenge Save lable to Insert
                    $(
                      ".tox-dialog__body-nav-item.tox-tab:contains('General')"
                    ).html("Embed"); //Change General lable to Embed
                    $(".tox-label:contains('Source')").html("Link"); //Change Source lable to Link
                    $(".tox-dialog__body-nav-item.tox-tab:contains('Upload')")
                      .click()
                      .focus();
                  }
                });
              },
              external_plugins: {
                //fullpage: "https://learningapps-ui-ppe.pearsoned.com/b0/dev/webcomponents-shared/latest/tinymce-fullpage/plugin.js",
                mathjax:
                  "https://learningapps-ui.pearson.com/b0/lass-ui-cdn-libs/v1.2.0/tinymce-mathjax/v1.0.7/plugin.js",
              },
              mathjax: {
                lib: "https://learningapps-ui.pearson.com/b0/lass-ui-cdn-libs/v1.2.0/matheditor/js/mathjax.js", //required path to mathjax
                // lib:"https://learningapps-ui-ppe.pearsoned.com/b0/dev/webcomponents-shared/latest/matheditor/mathjax/es5/tex-chtml-full.js",

                configUrl:
                  "https://learningapps-ui.pearson.com/b0/lass-ui-cdn-libs/v1.2.0/tinymce-mathjax/v1.0.7/config.js",
              },
            }}
            onEditorChange={this.handleEditorChange}
            onBeforeSetContent={this.onBeforeSetContent}
          />
        </div>
      </>
    );
  }
}

TextEditor.propTypes = {
  mathEditor: PropTypes.bool,
  initialContent: PropTypes.string,
  tinymceApiKey: PropTypes.string,
  onChange: PropTypes.func,
  toolbar: PropTypes.string,
  menubar: PropTypes.string,
  insertItems: PropTypes.string,
  height: PropTypes.number,
  setEditorInstance: PropTypes.func,
  refreshEditor: PropTypes.func,
  loader: PropTypes.bool,
  contentStyle: PropTypes.string,
  placeholderText: PropTypes.string,
};

TextEditor.defaultProps = {
  mathEditor: true,
  tinymceApiKey: "8rx0rjuz205wkmwv32ddw2a1hovrsavxlnx12rrkk4e594wb",
  height: 600,
  toolbar:
    "Focus | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview  print | insertfile image media link anchor codesample Math code | ltr rtl",
  menubar: "file edit view insert format tools table help",
  insertItems:
    "image link media inserttable Math codesample | charmap emoticons hr | pagebreak anchor toc | insertdatetime",
  loader: false,
  contentStyle: "",
  placeholderText: null,
};

export default TextEditor;
